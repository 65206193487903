import React, { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  const [portfolioData, setPortfolioData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const response = await axios.get('https://stockify-backend-production.up.railway.app/portfolio');
      const data = response.data;
      setPortfolioData(data);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <h1>Stock Portfolio!!</h1>
      <div id="portfolio">
        {portfolioData.map((stock) => (
          <div key={stock.id}>
            <h3>{stock.name}</h3>
            <p>{stock.symbol}</p>
            {/* Weitere Daten anzeigen */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
